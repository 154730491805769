<template>
	<ajax-form-modal v-model="isActive"
					 :form-options="{action: selectedParticipationSubmitType?.action ?? `/v1/athletes/${selectedUserKey}/events/${addParticipationData.event_id}/manual-participations`, method: 'POST', data: addParticipationData, notification: 'added'}"
					 :modal-options="{title: t('action.add_participation') + (selectedUserDisplayName ? ` ${t('conjunction.for')} ${selectedUserDisplayName}` : ''), subtitle: props.title, showCloseButton: false, buttonBreakColumn: 'xs'}"
					 :submit-text="t('action.add_participation')"
					 :hide-submit="!selectedUserKey || !selectedParticipationSubmitType || selectedParticipationSubmitType.hide_submit || (selectedParticipationSubmitType.value === 'upload' && !uploadParticipationClasses.length)"
					 @cancel="handleCanceledAddParticipation"
					 @success="handleAddedParticipation">

		<div v-if="!selectedUserKey">
			<form-user-select @select="handleSelectedUserOption"
							  :model-value="selectedUserKey"
							  :params="props.userSelectParams"
							  x-large
							  only-options/>
		</div>
		<div v-else-if="!selectedParticipationSubmitType" class="stretch-layout h-full">
			<add-btn v-for="type in participationSubmitTypes" :key="type.value" flat outlined class="flex-grow-1 my-8"
					 :icon="type.icon" :text="type.title"
					 :help="type.description" @click="selectAddParticipationType(type)"/>
		</div>
		<template v-else-if="selectedParticipationSubmitType.value === 'input'">
			<manual-participation-form-inputs :data="addParticipationData" :user-key="selectedUserKey" :disabled-fields="props.fixedData ? Object.keys(props.fixedData) : []"/>
		</template>
		<template v-else-if="selectedParticipationSubmitType.value === 'upload'">
			<loader v-if="isLoadingParticipationFile"/>
			<template v-else>
				<missing-info v-if="!uploadParticipationClasses.length" :text="t('missing.activity_file.events')" :button="participationSubmitTypes[0].title"
							  @click="selectAddParticipationType(participationSubmitTypes[0])"/>
				<form-select v-else name="participation_classes"
							 :placeholder="t('placeholder.select')"
							 :label="t('label.events')"
							 :rules="{required: true}"
							 :options="uploadParticipationClasses"
							 value-prop="id"
							 text-prop="event_name"
							 subtitle-prop="name"
							 image-prop="avatar_thumbnails"
							 multiple
							 v-model="addParticipationData.participation_classes"/>

				<map-map style="min-height: 200px" :tile-opacity="0.6" :focus-elements="uploadParticipationFileLatLngs" hide-controls>
					<map-polyline :lat-lngs="uploadParticipationFileLatLngs" color="#f0820a"/>
				</map-map>
			</template>
		</template>
		<template v-else-if="selectedParticipationSubmitType.value === 'connections'">
			<user-connections-form-inputs :user-key="props.userKey"/>
		</template>

		<input style="display: none" type="file" name="file" accept=".gpx,.fit,.tcx" ref="participationFileUploaderElement"
			   @change="handleSelectedParticipationFile"/>

		<template v-if="selectedParticipationSubmitType && selectedUserKey" #footer>
			<btn v-if="(participationSubmitTypes.length > 1 && selectedParticipationSubmitType) || (!props.userKey && selectedUserKey)" @click="navigateBack">
				<icon :icon="icons.back"/>
				<span>{{ t('action.back') }}</span>
			</btn>
			<span v-else></span>
			<btn v-if="selectedParticipationSubmitType?.value === 'upload' && !isLoadingParticipationFile" @click="addParticipationByFile()">
				<span>{{ t('action.choose_other_file') }}</span>
			</btn>
		</template>
	</ajax-form-modal>
</template>

<script setup lang="ts">
import {backIcon, uploadIcon, editIcon} from "@spoferan/nuxt-spoferan/icons";
import {mdiWatch} from "@mdi/js";

const route = useRoute();
const {t} = useI18n();
const {$auth, $apiFetch} = useNuxtApp();

const props = defineProps<{
	modelValue?: boolean,

	// A title to describe the page on which the participation gets added
	title?: string,

	// The fixed user key to add participations for.
	// If not specified, a user select is displayed first.
	userKey?: string|number,

	// The display name of the user to add participations for.
	userDisplayName?: string,

	// The params to pass to the user select for filtering the user options.
	userSelectParams?: object,

	// Whether to disable to file upload option
	disableUpload?: boolean,

	// Whether to disable to connections submit option
	disableConnections?: boolean,

	// The data to prefill the participation form with that can be changed by the user.
	// This cannot be used for the upload option.
	data?: object

	// The data to prefill the participation form with that cannot be changed by the user.
	// This cannot be used for the upload option.
	fixedData?: object
}>();

const emit = defineEmits(['update:modelValue', 'success', 'cancel']);

const icons = {
	back: backIcon,
};

const {isActive} = useToggleableComponent(props, emit);
watch(isActive, (newValue) => {
	if (newValue) {
		selectedParticipationSubmitType.value = participationSubmitTypes.value.length === 1 ? participationSubmitTypes.value[0] : null;
	}
});

const selectedUserKey = ref(props.userKey);
const selectedUserDisplayName = ref(props.userDisplayName);

const isLoadingParticipationFile = ref(false);
const participationFileUploaderElement = ref(null);
const addParticipationData = ref({...props.data ?? {}, ...props.fixedData ?? {}});

// The coordinates of the activity file track being uploaded for an attempt
const uploadParticipationFileLatLngs = ref([]);

// The qualified participation classes that were resolved for the uploaded activity file
const uploadParticipationClasses = ref([]);

const participationSubmitTypes = computed(() => {
	return [
		{value: 'input', title: t('label.manual_input'), description: t('text.participation.add_event_by_input'), icon: editIcon, hide_submit: false},
		{
			value: 'upload',
			title: t('action.upload_result'),
			description: t('text.participation.add_event_by_upload'),
			icon: uploadIcon,
			hide_submit: false,
			action: `/v1/athletes/${selectedUserKey.value}/participations/upload`
		},
		{value: 'connections', title: t('label.connections'), description: t('text.participation.add_event_by_connections'), icon: mdiWatch, hide_submit: true},
	].filter(type => type.value === 'input' || (!props.disableUpload && type.value === 'upload') || (!props.disableConnections && type.value === 'connections'))
});

const selectedParticipationSubmitType = ref(participationSubmitTypes.value.length === 1 ? participationSubmitTypes.value[0] : null);

function selectAddParticipationType(type) {
	if (type.value === 'upload') {
		addParticipationData.value = {};
		addParticipationByFile();
	} else {
		addParticipationData.value = {
			club_id: $auth.user.club_id,
			...(props.data ?? {}),
			...(props.fixedData ?? {}),
		};
		selectedParticipationSubmitType.value = type;
	}
}

/**
 * Opens the file explorer on the users machine to select an activity file for uploading a track.
 */
function addParticipationByFile() {
	if (participationFileUploaderElement.value) {
		participationFileUploaderElement.value.click();
	}
}

/**
 * Creates tracks based on the user's selected participation files.
 */
function handleSelectedParticipationFile(event) {
	const files = event.target.files;
	if (files && files[0]) {
		selectedParticipationSubmitType.value = participationSubmitTypes.value.find(type => type.value === 'upload');
		uploadParticipationFileLatLngs.value = [];
		uploadParticipationClasses.value = [];
		isLoadingParticipationFile.value = true;
		$apiFetch(`/v1/athletes/${selectedUserKey.value}/participations/check-upload`, {
			method: 'POST',
			params: {
				file: files[0]
			}
		}).then(({data}) => {
			addParticipationData.value.file = files[0];
			uploadParticipationFileLatLngs.value = data.activity.polyline;
			uploadParticipationClasses.value = data.participationClasses;
			if (uploadParticipationClasses.value.length === 1) {
				addParticipationData.value.participation_classes = [uploadParticipationClasses.value[0].id];
			}
		}).catch(() => {
			selectedParticipationSubmitType.value = null;
		}).finally(() => {
			isLoadingParticipationFile.value = false;

			// Clear the uploader input, so we can upload the same file twice
			participationFileUploaderElement.value.value = '';
		})
	}
}

function handleSelectedUserOption(option) {
	selectedUserDisplayName.value = option?.display_name
	selectedUserKey.value = option?.id;
}

function navigateBack() {
	if (selectedParticipationSubmitType.value && participationSubmitTypes.value.length > 1) {
		selectedParticipationSubmitType.value = null;
	} else if (!props.userKey && selectedUserKey.value) {
		selectedUserKey.value = null;
		selectedUserDisplayName.value = null;
	}
}

function handleCanceledAddParticipation() {
	selectedParticipationSubmitType.value = null;
	emit('cancel');
}

async function handleAddedParticipation() {
	isActive.value = false;
	selectedParticipationSubmitType.value = null;

	emit('success');
}

</script>